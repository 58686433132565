import BaseModule from "./BaseModule";
import { PlanFeeType, PlanRuleType } from "types/account";
import { FavoredKeyType } from "types/pix";

class Account extends BaseModule {
  instance: any;
  constructor(instance: any) {
    super(instance);
    this.instance = instance;
  }

  data() {
    return this.get("/ib/account");
  }

  plan(): Promise<{
    id: number;
    name: string;
    default: boolean;
    main_account_plan: boolean;
    charge_frequency: 'PER_TRANSACTION',
    plan_rules: PlanRuleType[];
    plan_fees?: PlanFeeType[];
    purpose?:string;

  }> {
    return this.get("/ib/account/plan");
  }

  favoreds(): Promise<FavoredKeyType[]> {
    return this.get("/ib/favoreds", { params: { type: "PIX_KEY" } });
  }

  removeFavored(favored: number) {
    return this.delete(`/ib/favoreds/${favored}`);
  }

  stats(): Promise<{
    charges: {
      [key: string]: {
        total: number;
        done: number;
        conversion: string;
      }
    };
    movements: {
      [key: string]: {
        CASH_IN: {
          total_amount: number;
          total_count: number;
        },
        CASH_OUT: {
          total_amount: number;
          total_count: number;
        }
      }
    };
  }> {
    return this.get("/ib/dashboard/stats");
  }
}

export default Account;
